<template>
    <div>
        <div class="w3-bar w3-top w3-theme-l4 w3-large" style="z-index: 4">
            <button id="wjudul" class="w3-bar-item w3-theme-d5 w3-btn w3-hover-none w3-hover-text-black w3-hide-small w3-hide-medium w3-small" style="width: 240px">
                <i class="fa fa-diamond"></i> &nbsp; <b> SOPAAH</b> <small>[{{namaapp}}]</small>
            </button>
            <button id="wopen" class="w3-bar-item w3-btn w3-hover-none w3-hover-text-black w3-hide-small w3-hide-medium w3-small" v-on:click="openMenu(); vmenu = vmenu == 'a' ? 'b' : 'a';">
            </button>
            <div class="w3-small">
                <div class="w3-dropdown-hover" style="position: fixed; right: 50px">
                    <a href="http://demo.do-event.com/eltama" class="w3-btn w3-theme-l4 w3-border-left"> <i class="fa fa-home"></i></a>
                    <button v-on:click="loadProfile()" class="w3-btn w3-theme-l4 w3-border-left" style="width: 200px" >
                        <i class="fa fa-user-circle-o"></i> {{ username }}<span class="caret"></span>
                    </button>
                    <div class="w3-dropdown-content w3-bar-block w3-theme-d5" style="width: 200px" id="menu-drop1" ></div>
                </div>
                <button class="w3-btn w3-theme-l4 w3-border-left w3-right" title="Log Out" v-on:click="logoutSystem()" style="width: 50px" >
                    <i class="fa fa-sign-out"></i>
                </button>
            </div>
            <button class="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey w3-small" v-on:click="openMenu()" >
                <i class="fa fa-bars"></i> &nbsp;Menu
            </button>
            <!-- <span class="w3-bar-item w3-right">Logo</span> -->
        </div>
        <nav class="w3-sidebar w3-collapse w3-animate-left w3-theme-d5" style="z-index: 3; width: 240px; top: 30px;overflow: auto; direction: rtl; text-align: left;" id="mySidebar" >
            <br />
            <div class="w3-container w3-row">
                <div class="w3-col s4">
                    <img src="assets/images/person.jpg" class="w3-circle w3-margin-right" style="width: 45px; height: 50px" />
                </div>
                <div class="w3-col s8 w3-bar">
                    <span >Welcome, <strong>{{ username }}</strong></span ><br />
                </div>
            </div>
            <hr />
            <div class="w3-bar-block" id="menuid" style="margin-bottom: 40px;">
                <a href="#/home" :key="'Main'" style="padding: 2px 0px 10px 8px; text-transform: uppercase;" class="w3-bar-item w3-button " :class="tabmenu=='Main' ? 'w3-large menu-aktif w3-theme-l1' : ''" >
					Dashboard &nbsp; <i class="fa fa-dashboard fa-fw"></i>
				</a>
				<div style="padding: 2px 0px 0px 8px; text-transform: uppercase;">
					Master &nbsp; <i class="fa fa-book" ></i>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'petugas'" to="petugas" class="w3-bar-item w3-button"
						style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='petugas' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Petugas &nbsp; <i class="fa fa-users"></i>
						</router-link>
					</div>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'layanan'" to="layanan" class="w3-bar-item w3-button"
						style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='layanan' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Pelayanan &nbsp; <i class="fa fa-archive"></i>
						</router-link>
					</div>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'bed'" to="bed" class="w3-bar-item w3-button"
						style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='bed' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Bed &nbsp; <i class="fa fa-bed"></i>
						</router-link>
					</div>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'info'" to="info" class="w3-bar-item w3-button"
						style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='info' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Info &nbsp; <i class="fa fa-info"></i>
						</router-link>
					</div>
					<!-- <div style="padding: 2px 0px 0px 5px">
						<router-link :key="'video'" to="video" class="w3-bar-item w3-button"
						style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='video' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Video &nbsp; <i class="fa fa-video-camera"></i>
						</router-link>
					</div> -->
				</div>
				<div style="padding: 2px 0px 0px 8px; text-transform: uppercase;">
					Transaksi &nbsp; <i class="fa fa-book" ></i>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'jadwal'" to="jadwal" class="w3-bar-item w3-button" style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='jadwal' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Jadwal &nbsp; <i class="fa fa-list"></i>
						</router-link>
					</div>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'pasien'" to="pasien" class="w3-bar-item w3-button" style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='pasien' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Pasien Online &nbsp; <i class="fa fa-users"></i>
						</router-link>
					</div>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'antrian'" to="antrian" class="w3-bar-item w3-button" style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='antrian' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Antrian &nbsp; <i class="fa fa-gears"></i>
						</router-link>
					</div>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'pengaduan'" to="pengaduan" class="w3-bar-item w3-button" style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='pengaduan' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Pengaduan &nbsp; <i class="fa fa-info"></i>
						</router-link>
					</div>
				</div>
				<div style="padding: 2px 0px 0px 8px; text-transform: uppercase;">
					Laporan &nbsp; <i class="fa fa-book" ></i>
					<div style="padding: 2px 0px 0px 5px">
						<router-link :key="'lapantrian'" to="lapantrian" class="w3-bar-item w3-button"
							style="padding: 2px 0px 0px 20px !important;" :class="tabmenu=='lapantrian' ? 'w3-large menu-aktif w3-theme-l1' : ''">
							Lap. Antrian <i class="fa fa-area-chart"></i>
						</router-link>
					</div>
				</div>
            </div>
        </nav>
        <div class="w3-main w3-theme-l5" style="margin-top: 34px; margin-left: 240px" id="main" >
            <router-tab class="w3-text-white" style="background:#f2f9fa !important" />
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "Dashboard",
    components: {},
    data() {
        return {
            username: "",
			screenHeight: 0,
            tabmenu: 'homefarmasi',
            namaapp : this.appName
        };
    },
    methods: {
        openMenu() {
			let mySidebar = document.getElementById("mySidebar");
			let overlayBg = document.getElementById("myOverlay");
			if (
				mySidebar.style.display === "block!important" ||
				mySidebar.style.display === "block"
			) {
				mySidebar.style.display = "none";
				overlayBg.style.display = "none";
				mySidebar.style.zIndex = "0";
			} else {
				mySidebar.style.display = "block";
				overlayBg.style.display = "block";
				mySidebar.style.zIndex = "3";
			}
		},
        logoutSystem() {
            var self = this;
            swal(
                {
                    title: "Keluar Program",
                    text: "Yakin Ingin Keluar Program ..!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Keluar",
                    cancelButtonText: "Tidak",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                },
                function (isConfirm) {
                    if (isConfirm) {
                        localStorage.clear();
                        self.$router.push("/../login");
                        location.reload();
                    }
                }
            );
        },
        loadProfile(){
            this.$router.push("/profile");
        }
    },
    watch: {
        $route: function (baru, lama) {
            this.tabmenu = baru.name;
        },
    },
    mounted() {
        this.namaapp = this.appName;
        this.screenHeight = window.innerHeight;
		this.tabmenu = this.$route.name;
		this.username = localStorage.username;
    },
};
</script>

<style scoped>
    .d-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .main {
        margin-left: 15%;
        padding: 0;
    }
    .side {
        width: 15%;
    }
	.menu-aktif{
		border-radius: 50px 0px 0px 50px;
		margin-left: 5px;
		/*padding-top: 20px;*/
		height: 32px;
	}
	
	.menu-induk {
		padding: 5px!important;
		text-align: center;
		cursor: pointer;
	}
	
	.menu-anak{
		border-radius: 50px 50px 50px 50px;
		/* height: 25px; */
		/* width: 25px; */
		/* background-color: rgb(74, 155, 128); */
	}
</style>
